import { useLocaleStore } from "../stores/LocaleStore.js";
import { useServerFetch } from "./useServerFetch.js";
import { useBreadcrumbStore } from "../stores/BreadcrumbStore.js";
import { useRouterStore } from "../stores/RouterStore.js";

export const useServerPage = async (url, alias = null) => {
    const localeStore = useLocaleStore();
    const routerStore = useRouterStore();
    const breadcrumbStore = useBreadcrumbStore();

    const locale = localeStore.prefix;
    const langUrl = `/${locale}${alias || url}`;

    let pageType;

    if (routerStore.hasPage(langUrl)) {
        pageType = routerStore.getPage(langUrl);
    } else {
        const { data, error } = await useServerFetch(url);
        if (error.value) {
            throw createError(error.value);
        }
        const fetchedData = data?.value?.data;
        pageType = { ...fetchedData, url: langUrl };
        await routerStore.addPage(pageType);
    }

    breadcrumbStore.items = pageType?.breadcrumbs || [];
    return pageType;
};
